<template>
  <div id="mycard">
    <div class="content">

<!--      <SaveButton @handle_save_action="(event) => my_click_evt(event)"></SaveButton>-->

      <!-- Horizontal cards -->
      <div class="mb-3 pt-2">
        <h6 class="mb-0 font-weight-semibold">
<!--          Doctor Brith Day Greetings-->
          Doctors to be greeted for theirs Birth Day
        </h6>
        <span class="text-muted d-block">{{date_title}}</span>
      </div>d

      <div class="row" >


        <div class="col-xl-3 col-lg-6" v-for="doctor in doctor_dobs">
          <div class="card card-body">
            <div class="media">
              <div class="mr-3">
                <a href="#">
                  <img src="static/global_assets/images/demo/users/doctor_logo.jpg" class="rounded-circle" width="42" height="42" alt="">
                </a>
              </div>

              <div class="media-body">
                <h6 class="mb-0">{{doctor.name}}</h6>
                <span class="text-muted">{{doctor.qualification}}</span>
              </div>

              <div class="ml-3 align-self-center">
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown"><i class="icon-menu7"></i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"><i class="icon-comment-discussion"></i> Start chat</a>
                      <a href="#" class="dropdown-item"><i class="icon-phone2"></i> Make a call</a>
                      <a href="#" class="dropdown-item"><i class="icon-mail5"></i> Send mail</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- /horizontal cards -->


      <!-- Horizontal cards -->
      <div class="mb-3 pt-2">
        <h6 class="mb-0 font-weight-semibold">
          Doctors to be greeted for theirs Wedding Day
        </h6>
      </div>

      <div class="row" >


        <div class="col-xl-3 col-lg-6" v-for="doctor in doctor_dows">
          <div class="card card-body">
            <div class="media">
              <div class="mr-3">
                <a href="#">
                  <img src="static/global_assets/images/demo/users/doctor_logo.jpg" class="rounded-circle" width="42" height="42" alt="">
                </a>
              </div>

              <div class="media-body">
                <h6 class="mb-0">{{doctor.name}}</h6>
                <span class="text-muted">{{doctor.qualification}}</span>
              </div>

              <div class="ml-3 align-self-center">
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown"><i class="icon-menu7"></i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"><i class="icon-comment-discussion"></i> Start chat</a>
                      <a href="#" class="dropdown-item"><i class="icon-phone2"></i> Make a call</a>
                      <a href="#" class="dropdown-item"><i class="icon-mail5"></i> Send mail</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- /horizontal cards -->


    </div>
  </div>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/SaveButton'
export default {
  name: 'Greetings',
  components: { SaveButton },
  store,
  data(){
    return {
      working_date : moment(),
      date_title : '',
      doctor_dobs : [],
      doctor_dows : [],
    }
  },
  created () {
    this.$data.working_date = this.$store.state.user.working_date;
  },
  mounted () {
    const self = this;

    try {

      let today = moment(new Date());

      this.$store.state.user.doctors.forEach((doctor)=>{

        if( moment(doctor.dob).date()   == today.date()  && moment(doctor.dob).month()   == today.month() ){
          self.$data.doctor_dobs.push(doctor);
        }

        if( moment(doctor.dow).date()   == today.date()  && moment(doctor.dow).month()   == today.month() ){
          self.$data.doctor_dows.push(doctor);
        }

      });

      self.$data.date_title = moment(self.$data.working_date).format('ll');

      // self.loadBirthDayData();
      // self.loadWeddingDayData();
    }catch (e) {
      alert(e);
    }
  },
  methods:{
    loadBirthDayData(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      try {

        $('#greetings_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.doctor_dobs = [];

        //alert(`${process.env.VUE_APP_ROOT_API}api/dayplans/doctor/birthdays/${self.$store.state.user.territory_id}`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/dayplans/doctor/birthdays/${self.$store.state.user.territory_id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#greetings_card').unblock();

          if (resp.ok) {

            if (!_.isNull(resp.data)) {
              self.$data.doctor_dobs = resp.data;

              // $('#area_table').bootstrapTable('load', self.$store.state.user.areas);
              // $('#area_table').bootstrapTable('checkBy', {field: 'id', values: self.$data.today_areas});

            }

          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#greetings_card').unblock();
        });
      }catch (e) {
        alert(e);
      }
    },
    loadWeddingDayData(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      try {

        $('#greetings_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.doctor_dows = [];

        fetch(`${process.env.VUE_APP_ROOT_API}api/dayplans/doctor/weddingdays/${self.$store.state.user.territory_id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#greetings_card').unblock();

          if (resp.ok) {

            if (!_.isNull(resp.data)) {
              self.$data.doctor_dows = resp.data;

              console.log(JSON.parse(resp.data));

            }

          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#greetings_card').unblock();
        });
      }catch (e) {
        alert(e);
      }
    },
    loadDayPlan(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      try {

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.today_areas = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/tourplanday/emp/${self.$store.state.user.id}/${self.$data.working_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#mycard').unblock();

          if (resp.ok) {

            if (!_.isNull(resp.data)) {

              resp.data.areas1.forEach( (a)=>{
                self.$data.today_areas.push(a.area_id);
              });

              resp.data.areas2.forEach( (a)=>{
                self.$data.today_areas.push(a.area_id);
              });

              self.loadReportingDoctors();
            }

          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#mycard').unblock();
        });
      }catch (e) {
        alert(e);
      }
    },
    loadReportingDoctors(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      let areas =  JSON.stringify(self.$data.today_areas);

      areas = areas.replace('[','');
      areas = areas.replace(']','');

      self.$data.reportings = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/doctors/active/area/${areas}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            resp.data.forEach((doctor)=>{
              let rep = {"id":0,"date":"","doctor":{"id":0,"name":"","hospital":"","sex":"M","address":"","area_id":1,"qualification":"qualification","speciality":"","class":"","dob":"1900-01-01","dow":"","no_of_visits":0,"last_visit":"2000-01-01","mobile":"","email":"","web":"","whatsapp":"","spouse_name":"","spouse_dob":"","spouse_profession":"","data":"","area":{"id":1,"name":""},"categories":[]},"visit_time":"","visited":false,"not_met_type":"","not_met_remarks":"","lbl_reminder":false,"sample_reminder":false,"cmc_reminder":false,"promoted_products":[]};
              rep.doctor = doctor;
              self.$data.reportings.push(rep);
            });
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });

    },
    my_click_evt(event){
      console.log(JSON.stringify(event));
      alert('my_click_evt');
    }
  }
}
</script>

<style scoped>

</style>
